import './assets/css/animate.css'
import './assets/css/utilities.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './Route/AppRoutes';
import Layout from './components/Common/Layout';

function App() {
  return (
    <BrowserRouter>
    {/* <Layout> */}
      <AppRoutes/>
    {/* </Layout> */}
  </BrowserRouter>
  );
}

export default App;
