import React, { useEffect } from 'react'

const ModalLayout = ({ children, open, close, modalWidth, darkThemeCls, grandOpening, fadeIn, cls }) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27 && open) {
        close(); // Close the modal if Escape key is pressed and modal is open
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, close]);
  return (
    <>
      <div className={`"modal fade custom-modal ${darkThemeCls ? 'dark-theme-modal' : ''} ${cls ? cls : ''} ${grandOpening ? "grand-opening-modal" : ""} ${fadeIn ? 'fadeIn' : ''} ${open ? "show" : ""}`} 
        id="commonModal" 
        data-bs-backdrop="static" 
        data-bs-keyboard="false" 
        tabIndex="-1" aria-labelledby="staticBackdropLabel" 
      >
      <div className={`overlay-modal cs-overflow-modal ${open ? "open" : ""}`} onClick={close}></div>
        <div className={`modal-dialog modal-dialog-centered`} style={{maxWidth: modalWidth}}>
            {children}
        </div>
      </div>
    </>
  )
}

export default ModalLayout