import React from 'react'
import { Link } from 'react-router-dom'
import { getImages } from './const'

const Header = ({secref}) => {
  return (
    <>
        <header className='header-wrap' ref={secref}>
            <div className='container container-lg'>
                <div className='header-flex d-flex align-items-center justify-content-between'>
                    <div className='main-logo'>
                        <Link to="/"><img src={getImages('alpha-one-logo.webp')}/></Link>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div>
                            <Link to="tel:5127771240" className='phone-no'>
                                <span className='phone-icon'></span> <span className='d-none d-md-inline-block'>(512) 777-1240</span>
                            </Link>
                        </div>
                        <div className='social-links ms-4'>
                            <a href='https://www.facebook.com/alphaonemotors/' target='_blank' className='ms-3'><img src={getImages('fb-icon.png')}/></a>
                            <a href='https://www.instagram.com/alpha_one_motors/' target='_blank' className='ms-3'><img src={getImages('instagram-font-awesome.png')}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
}

export default Header