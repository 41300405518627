import React from 'react'
import { getImages } from './const'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
        <footer className='footer-wrap'>
            <div className='container container-lg'>
                <div className='footer-top d-flex align-items-center justify-content-between'>
                    <div className='ft-left'>
                        <div className='mb-4'>
                            <Link to="/" className='footer-logo'><img src={getImages('alpha-one-logo.webp')}/></Link>
                        </div>
                        {/* <div className='sm-title font-18 mb-3'>2301 Double Creek Dr. Suite 270, Round Rock, Texas 78664</div> */}
                        <div className='sm-title font-16 mb-3'>
                            <Link to="tel:5127771240"><span className='text-gray'>Sales:</span> (512) 777-1240</Link>
                        </div>
                        {/* <div className='sm-title font-16 mb-3'>
                            <Link to="tel:5127771240"><span className='text-gray'>Service:</span> (512) 777-1240</Link>
                        </div> */}
                    </div>
                    <div className='ft-right'>
                        <div className='sm-title font-16 mb-4 text-uppercase fw-500'>Store Hours</div>
                        <div className='d-flex align-items-center mb-3 justify-content-between store-hours-info'>
                            <div className='sm-title font-16 fw-500 text-gray'>Monday - Friday</div>
                            <div className='sm-title font-16 w-80px fw-500 text-gray'>10am - 5pm</div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-3 store-hours-info'>
                            <div className='sm-title font-16 fw-500 text-gray'>Saturday</div>
                            <div className='sm-title font-16 w-80px fw-500 text-gray'>Appointment Only</div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-3 store-hours-info'>
                            <div className='sm-title font-16 fw-500 text-gray'>Sunday</div>
                            <div className='sm-title font-16 w-80px fw-500 text-gray'>Closed</div>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom d-flex align-items-center justify-content-between'>
                    <div className='social-icons order-2'>
                        <a href='https://www.facebook.com/alphaonemotors/' target='_blank'><img src={getImages('fbf-icon.png')}/></a>
                        <a href='https://www.instagram.com/alpha_one_motors/' target='_blank'><img src={getImages('insta-icon.png')}/></a>
                        {/* <a href='#'><img src={getImages('yt-icon.png')}/></a>
                        <a href='#'><img src={getImages('tw-icon.png')}/></a>
                        <a href='#'><img src={getImages('pin-icon.png')}/></a> */}
                    </div>
                    <div className='copyright'>&copy; 2025 Alpha One Motors. All Rights Reserved.</div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer