import React, { useContext } from 'react'
import { getImages } from '../Common/const'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { VehicleContext } from '../Context/VehicleContext';

const makes = [
    { name: 'Toyota', models: ['Corolla', 'Camry', 'RAV4'] },
    { name: 'Honda', models: ['Civic', 'Accord', 'CR-V'] },
    { name: 'Ford', models: ['F-150', 'Mustang', 'Escape'] }
];

const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    year: Yup.string().required('Year is required'),
    make: Yup.string().required('Make is required'),
    model: Yup.string().required('Model is required'),
    comments: Yup.string()
});

const VehicleConsignmentInquiry = ({ close }) => {

    const {submitContactForm} = useContext(VehicleContext);

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title filter-modal-title">
                        Vehicle Consignment Inquiry
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <img src={getImages('white-close.svg')} />
                    </button>
                </div>
                <div className="modal-body py-4 px-120">
                    <div className='lg-title text-center text-black fw-400 pt-3 pb-4'>Vehicle Consignment Inquiry</div>
                    <div className='vci-box custom-form'>
                        <Formik
                            initialValues={{ fullName: '', email: '', phone: '', year: '', make: '', model: '', comments: '' }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                console.log(values);
                                submitContactForm(values);
                                resetForm();
                                close();
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label>Full Name<span className='text-danger'>*</span></label>
                                            <Field type='text' name='fullName' className='form-control' />
                                            <ErrorMessage name='fullName' component='div' className='text-error text-danger' />
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Email<span className='text-danger'>*</span></label>
                                            <Field type='text' name='email' className='form-control' />
                                            <ErrorMessage name='email' component='div' className='text-error text-danger' />
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Phone<span className='text-danger'>*</span></label>
                                            <Field type='text' name='phone' className='form-control' />
                                            <ErrorMessage name='phone' component='div' className='text-error text-danger' />
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Year<span className='text-danger'>*</span></label>
                                            <Field type='text' name='year' className='form-control' />
                                            <ErrorMessage name='year' component='div' className='text-error text-danger' />
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Make<span className='text-danger'>*</span></label>
                                            <Field type='text' name='make' className='form-control' />
                                            <ErrorMessage name='make' component='div' className='text-error text-danger' />
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Model<span className='text-danger'>*</span></label>
                                            <Field type='text' name='model' className='form-control' />
                                            <ErrorMessage name='model' component='div' className='text-error text-danger' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group h-100 mb-3'>
                                            <label>Comments</label>
                                            <Field as='textarea' name='comments' className='form-control' />
                                        </div>
                                    </div>
                                    <div className='col-12 text-center mt-4'>
                                        <button type='submit' className='black-btn w-240'>SEND</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VehicleConsignmentInquiry